main section.kontakt {
  @include section-white;
  border-top: 1px solid $primary-color;

  div.inner {
    @include section-inner-container;
    @include title-component;
    @include button;

    div.form-group {
      position: relative;
      padding-bottom: 0.5em;

      &:last-child {
        display: flex;
        flex-direction: column;
      }

      button {
        width: fit-content;
        align-self: flex-end;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray-border-2;
      }

      &:first-child {
        border-top: 1px solid $gray-border-2;
      }

      .form-control {
        @include form-control;
      }

      label {
        @include form-label;
      }

      input,
      textarea {
        @include input-and-textarea;
      }

      input:focus ~ label,
      textarea:focus ~ label {
        color: $primary-color;
        opacity: 1;
        top: 0;
      }

      input:not(:placeholder-shown) ~ label,
      textarea:not(:placeholder-shown) ~ label {
        color: $secondary-color;
        opacity: 1;
        top: 0;
      }

      textarea.form-control {
        height: auto;
        overflow: auto;
        resize: none;
      }

      p {
        color: $danger-color;
        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  }
}
