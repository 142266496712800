footer {
  @include text-center;
  @include footer-font;
  @include section-light;

  span {
    margin: 0 10px;
  }

  div.footer {
    padding-top: 1.2em;
    display: grid;
    grid-template-rows: auto;

    div.inner {
      margin: 0 auto;
      width: 65rem;

      @media screen and (max-width: 1280px) {
        width: 90vw;
      }
    }
  }

  div.col-2 {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;

    strong.dash {
      margin-left: 16px;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: auto;
      grid-template-rows: auto auto;
    }
  }

  div.col-3 {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;

    @media screen and (max-width: 768px) {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;

      span:last-child {
        grid-column: 1 / span 2;
      }
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;

      span:last-child {
        grid-column: 1 / 1;
      }
    }
  }

  div.col-2-equal {
    display: grid;
    grid-template-columns: 5fr 7fr;
    justify-content: center;

    div:first-child {
      display: flex;
      justify-content: flex-end;
    }

    div:last-child {
      display: flex;
      justify-content: flex-start;
      padding-left: 2rem;
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: 5fr 7fr;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 4fr 6fr;

      div:last-child {
        display: grid;
      }
    }
  }
}
