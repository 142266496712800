main section.formulare {
  @include section-light;
  @include section-font-size;

  div.inner {
    @include section-inner-container;
    @include title-component;

    div.main-content {
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      margin-bottom: 3vh;

      div:first-child {
        margin: 0 35% 0 0;

        p {
          margin-bottom: 1.25vh;
        }
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        div:first-child {
          margin: 0;
        }

        div:last-child {
          margin: 0 0 0 5vw;
        }
      }
    }
  }
}
