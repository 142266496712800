header {
  @include text-center;
  @include section-light;

  padding: 200px 0px 100px 0px;

  div.inner {
    width: 90%;
    margin: 0 auto;
    display: grid;
    justify-items: center;

    img,picture {
      border-radius: 50%;
      width: 256px;
      height: 256px;
    }
  }

  span.name {
    @include font-large;
    font-size: 2.75rem;
    margin-top: 1.5rem;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }

  hr.line-break {
    @include line-break;
  }

  span.skills {
    font-size: 1.65rem;

    @media screen and (max-width: 768px) {
      font-size: 1.15rem;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0px 50px 0px;
  }
}
