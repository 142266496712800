// https://fonts.google.com/specimen/Open+Sans?query=Open+Sans
@font-face {
	font-family: 'OpenSans';
	src: url('../fonts/OpenSans.ttf') format("truetype supports variations"), url('../fonts/OpenSans.ttf') format("truetype-variations");
  font-weight: 100 1000;
}

/* https://github.com/mrkelly/lato/tree/master/font */
@font-face {
  font-family: "Lato";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"),
    url(../fonts/lato-regular.woff2) format("woff2");
}
@font-face {
  font-family: "Lato";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(../fonts/lato-bold.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  src: local("Lato Italic"), local("Lato-Italic"),
    url(../fonts/lato-italic.woff2) format("woff2");
}
@font-face {
  font-family: "Lato";
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: local("Lato Bolditalic"), local("Lato-Bolditalic"),
    url(../fonts/lato-bolditalic.woff2) format("woff2");
}
