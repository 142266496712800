nav {
  @include section-dark;
  @include font-large;

  height: fit-content;
  padding: 3vh 0;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.3s;

  a.highlighted {
    color: $hightlight-color;
  }

  a:hover {
    text-decoration: none;
    color: $hightlight-color;
    transition: all 0.3s;
  }

  div.inner {
    width: 1150px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;

    img {
      max-height: 20px;
      display: inline;
      margin-right: 7px;
      vertical-align: baseline;
    }

    div.brand {
      display: flex;
      z-index: 90;

      img {
        align-self: center;
        margin-right: 1rem;
      }

      a {
        font-size: 1.85rem;
        transition: all 0.3s;

        @media screen and (max-width: 1200px) {
          font-size: 1.15rem !important;
        }
      }
    }

    div.links {
      overflow: visible;
      width: 90%;
      justify-self: flex-end;
      transition: height 0.3s ease-in-out, padding 0.3s ease-in-out,
        border-top 0.3s ease-in-out;

      img {
        height: 1rem;
        align-self: center;
        margin-bottom: 3px;

        @media screen and (max-width: 1200px) {
          margin-bottom: 5px;
        }
      }

      div.container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a {
          font-size: 1.25rem;
          height: fit-content;

          @media screen and (max-width: 1200px) {
            font-size: 1rem;
          }

          @media screen and (max-width: 768px) {
            font-weight: 700;
            letter-spacing: 2px;
            font-size: 0.875rem;
          }
        }

        img {
          margin: 0;
          align-self: center;
          width: 26px;
          height: 16px;
        }

        div.dropdown {
          position: relative;
          display: inline-block;

          @media screen and (max-width: 768px) {
            display: flex;
          }
        }

        div.dropdown-content {
          display: none;
          flex-direction: column;
          align-items: center;
          position: absolute;
          padding: 3px 7px;
          background-color: $primary-color;
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.4);

          @media screen and (max-width: 768px) {
            flex-direction: row;
            padding: 0;
            background-color: #00000000;
            box-shadow: none;
            display: flex;
            position: relative;

            a {
              padding: 0 6px;
            }
          }
        }

        div.dropdown-head {
          height: 100%;
          display: flex;
          align-items: center;

          a {
            padding: 0 6px;
          }

          img.up {
            display: none;
            transform: rotate(180deg);
          }

          @media screen and (max-width: 768px) {
            img.down {
              display: none;
              transform: rotate(-90deg);
            }
          }
        }

        @media screen and (min-width: 768px) {
          div.dropdown:hover div.dropdown-content {
            display: flex;
          }

          div.dropdown:hover img.down {
            display: none;
          }

          div.dropdown:hover img.up {
            display: block;
          }
        }

        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: auto;
          grid-template-rows: auto auto auto;
          row-gap: 1.35rem;
        }
      }

      @media screen and (max-width: 1200px) {
        width: 27rem;
        padding: 1rem 0%;
      }

      @media screen and (max-width: 1025px) {
        width: 25rem;
        padding: 1rem 0%;
      }

      @media screen and (max-width: 768px) {
        overflow: hidden;
        justify-self: flex-start;
        width: 100%;
        position: absolute;
        z-index: 1;
        top: 6vh;
        margin-left: -2.5%;
        padding: 0 0 0 2.5%;
        border-top: 1px solid #a8957a00;
        background-color: $secondary-color;
        height: 0px;
      }
    }

    button.menu {
      z-index: 90;
      width: 40px;
      height: 35px;
      padding: 5px;
      border-radius: 5px;
      background-color: transparent;
      border: 1px solid $button-hightlight;
      align-self: center;
      justify-self: center;
      text-decoration: none;
      display: none;

      &:hover {
        background-color: $button-hightlight;
        cursor: pointer;
      }

      div.menu-line {
        background-color: $white;
        border-radius: 2px;
        height: 3px;

        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }

      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 1000px;
    }

    @media screen and (max-width: 1025px) {
      grid-template-columns: 10fr 1fr;
      grid-template-rows: auto;
      margin: 0 2.5%;
    }

    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 1025px) {
    height: 11vh;
  }

  @media screen and (max-width: 768px) {
    height: 6vh;
  }
}

nav.ru,
nav.uk {
  div.inner {
    display: flex;
    flex-direction: column;

    div.brand {
      display: flex;
      align-self: flex-start;

      a {
        font-size: 1.85rem;

        @media screen and (max-width: 768px) {
          font-size: 1.15rem !important;
        }
      }

      @media screen and (max-width: 768px) {
        align-self: center;
      }
    }

    div.links {
      align-self: flex-end;
      width: 700px;
      padding-top: 3.5vh;
      padding-bottom: 1vh;

      div.container {
        a {
          font-size: 1.25rem;

          @media screen and (max-width: 768px) {
            font-weight: 700;
            letter-spacing: 2px;
            font-size: 0.875rem;
          }
        }

        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: auto;
          grid-template-rows: auto auto auto;
          row-gap: 1.35rem;
          margin-top: 2.5%;
        }
      }

      @media screen and (max-width: 1025px) {
        width: 600px;
        margin-right: 3%;
      }

      @media screen and (max-width: 768px) {
        padding: 0 0 0 2.5%;
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      width: 1150px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 10fr 1fr;
      grid-template-rows: auto;
      margin: 0 2.5%;
    }
  }

  @media screen and (max-width: 1025px) {
    height: fit-content;
  }

  @media screen and (max-width: 768px) {
    height: 6vh;
  }
}
