main section.rechtsgebiete {
  @include section-white;
  @include section-font-size;

  div.inner {
    @include section-inner-container;
    @include title-component;

    ul.rechtsgebiete-list {
      margin: 5% 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);

      li {
        margin-bottom: -1px;
        background-color: $white;
        border: 1px solid $gray-border;
        height: 4rem;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: $gray-border;
        }

        @media screen and (max-width: 900px) {
          height: 5rem;
        }
      }

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(8, 1fr);
      }
    }

    ul.mitgliedschaften-list,
    ul.zertifizierungen-list {
      margin-left: 3rem;
    }

    div.first-p p {
      text-align: center;
    }

    p,
    h3 {
      text-align: left;
    }

    h3 {
      @include h3-section-subtitle;
    }
  }
}
