main section.schadenregulierung {
  @include section-white;
  @include section-font-size;

  div.inner {
    @include section-inner-container;
    @include title-component;
    @include button;

    div.main-content {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 1.5rem;
      }

      a {
        width: fit-content;
        align-self: flex-end;
      }
    }
  }
}
