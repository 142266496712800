div.modal {
  @include section-white;
  @include section-font-size;

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  overflow-y: auto;
  display: none;

  div.inner {
    @include title-component;
    @include button;
    @include section-inner-container;

    padding-top: 12vh;
    width: 900px;

    a.close {
      text-decoration: none;
      height: fit-content;
      font-size: 70px;
      font-weight: 200;
      position: absolute;
      top: 0;
      right: 3%;
      color: $secondary-color;

      &:hover {
        color: $primary-color;
      }
    }

    div.content {
      display: flex;
      flex-direction: column;

      button {
        width: fit-content;
        align-self: center;
        margin-top: $margin;
      }

      p {
        margin-bottom: $margin;
      }

      ul {
        margin-left: 2.5rem;
        margin-bottom: $margin;
      }

      h3 {
        @include font-large;
        font-size: 1.6rem;
        margin-bottom: $margin;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 700px;
    }

    @media screen and (max-width: 900px) {
      width: 600px;
    }

    @media screen and (max-width: 768px) {
      width: 85%;
    }
  }
}

#schadenregulierung {
  h3 {
    font-size: 1.6rem;
    margin-bottom: $margin;
    text-transform: capitalize;
    text-align: center;

    &:not(:first-child) {
      margin-top: $margin * 3;
    }
  }

  form button {
    display: block;
    width: 100px;
    margin-left: 90%;
  }

  div.form-group {
    position: relative;
    padding-bottom: 0.5em;

    &:last-child {
      display: flex;
      flex-direction: column;
    }

    button {
      width: fit-content;
      align-self: flex-end;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-border-2;
    }

    &:first-child {
      border-top: 1px solid $gray-border-2;
    }

    .form-control {
      @include form-control;
    }

    label {
      @include form-label;
      left: 50%;
    }

    input,
    textarea {
      @include input-and-textarea;
    }

    input:focus ~ label,
    textarea:focus ~ label {
      color: $primary-color;
      opacity: 1;
      top: 0;
    }

    input:not(:placeholder-shown) ~ label,
    textarea:not(:placeholder-shown) ~ label {
      color: $secondary-color;
      opacity: 1;
      top: 0;
    }

    textarea.form-control {
      height: auto;
      overflow: auto;
      resize: none;
    }

    label {
      @include form-label;
    }
  }
}
