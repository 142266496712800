.footer-legal {
  @include section-dark;
  @include footer-font;

  span a {
    font-size: 0.6em;
  }

  padding: 1.2em 0;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}
