$primary-color: #a8957a;
$secondary-color: #2f1502;
$white: #ffffff;
$hightlight-color: #a8957a;
$gray-border: #ecf0f1;
$gray-border-2: #eeeeee;
$form-text: #2c3e50;
$form-palceholder: #4b688577;
$danger-color: #e74c3c;
$success-color: #22a800;
$button-hightlight: #1a242f;
$margin: 0.65rem;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 1.42857143;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

nav div.inner div.links.show {
  height: 180px !important;
  padding: 2.5%;
  border-top: 1px solid #a8957a40;
}

div.modal.show {
  display: block !important;
}

div#success_schaden {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  padding-bottom: 1rem;
  display: none;
}

div#success_schaden.success {
  display: block;
  color: $success-color;
}

div#success_schaden.fail {
  display: block;
  color: $danger-color;
}

::placeholder {
  color: $form-palceholder;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
