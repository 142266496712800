@mixin text-center {
  text-align: center !important;
}

@mixin footer-font {
  font-size: 1.2em;
  line-height: 2.2em;
}

@mixin font-large {
  text-transform: uppercase;
  font-family:  "OpenSans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

@mixin section-dark {
  background-color: $secondary-color;
  color: $white;

  a {
    color: $white;
  }
}

@mixin section-light {
  background-color: $primary-color;
  color: $white;

  a {
    color: $white;
  }
}

@mixin section-white {
  background-color: $white;
  color: $secondary-color;

  a {
    color: $primary-color;
  }
}

@mixin line-break {
  padding: 0;
  border: none;
  border-top: solid 5px;
  text-align: center;
  max-width: 250px;
  margin: 25px auto 30px;
  width: 250px;
}

@mixin section-inner-container {
  margin: 0 auto;
  width: 950px;
  padding: 10vh 0;

  @media screen and (max-width: 1200px) {
    width: 750px;
  }

  @media screen and (max-width: 900px) {
    width: 650px;
  }

  @media screen and (max-width: 768px) {
    width: 85%;
  }
}

@mixin section-font-size {
  font-size: 1.25rem;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
}

@mixin h2-section-title {
  @include font-large;
  font-size: 2.75rem;
  text-align: center;

  @media screen and (max-width: 640px) {
    font-size: 1.5rem;
  }
}

@mixin h3-section-subtitle {
  @include font-large;
  text-transform: none;
  font-size: 1.4rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
}

@mixin title-component {
  h2 {
    @include h2-section-title;
  }

  hr.line-break {
    @include line-break;
  }
}

@mixin button {
  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;

    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    text-decoration: none;

    border: 1px solid $primary-color;
    white-space: nowrap;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 1.42857143;
    border-radius: 4px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    background-color: $primary-color;
    color: $white;

    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }

    @media screen and (max-width: 640px) {
      font-size: 0.8rem;
    }
  }
}

@mixin form-control {
  display: block;
  width: 100%;
  height: 43px;
  padding: 40px 15px 20px 15px;
  line-height: 1.42857143;
  color: $form-text;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-size: 22px;
}

@mixin input-and-textarea {
  z-index: 0;
  position: relative;
  border: none;
  outline: 0;
  font-size: 1.5em;
  font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;

  @media screen and (max-width: 640px) {
    font-size: 1.2em;
  }
}

@mixin form-label {
  pointer-events: none;
  display: block;
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 20px;
  margin: 0;
  font-size: 0.85em;
  line-height: 1.764705882em;
  vertical-align: baseline;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  transition: top 0.3s ease, opacity 0.3s ease;
}
